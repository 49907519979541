<template>
  <div>
    <va-card class="mb-4">
      <va-card-title class="display-3 ml-3">{{
        $t("menu.organizations")
      }}</va-card-title>
    </va-card>

    <va-card class="mb-4">
      <va-card-actions align="right">
        <va-button icon="fa-icon fa fa-plus" @click="showAddOrg = true">{{
          $t("org_view.add_org")
        }}</va-button>
      </va-card-actions>

      <va-card-content>
        <div class="row">
          <div class="flex xs12 sm3 md4 lg3 xl2">
            <va-select
              v-model.number="searchBySelected"
              :label="$t('org_view.search_by')"
              text-by="label"
              value-by="id"
              track-by="id"
              :options="searchBy"
            />
          </div>

          <div class="flex xs12 sm7 md6 lg5 xl4">
            <va-input
              v-model="term"
              :placeholder="
                $t('org_view.search_by') +
                ' ' +
                searchBy[searchBySelected].label
              "
              clearable
            >
              <template #prependInner>
                <va-icon name="fa fa-search" />
              </template>
            </va-input>
          </div>

          <div class="flex xs12 sm2 md2 lg2 xl2 offset--lg2 offset--xl4">
            <va-select
              v-model.number="perPage"
              :label="$t('tables_common.per_page')"
              :options="perPageOptions"
            />
          </div>
        </div>

        <va-data-table
          :items="filteredData"
          :columns="fields"
          :per-page="perPage"
          :current-page="currentPage"
          :no-data-html="$t('tables_common.no_data')"
          :hoverable="true"
          :loading="loading"
          :striped="true"
        >
          <template #header(actions)></template>

          <template #cell(actions)="{ source: actions }">
            <div v-if="actions.type !== 'parent'">
              <va-popover
                :message="`${$t('tables_common.edit')}`"
                placement="top"
              >
                <va-button
                  flat
                  color="info"
                  icon="fa fa-pencil"
                  @click="editOrgModal(actions.id)"
                />
              </va-popover>

              <va-popover
                :message="`${$t('tables_common.delete')}`"
                placement="top"
              >
                <va-button
                  flat
                  color="danger"
                  icon="fa fa-trash"
                  @click="removeOrg(actions.id)"
                />
              </va-popover>
            </div>
          </template>
        </va-data-table>

        <div class="flex-center mt-3">
          <va-pagination
            v-if="pages > 1"
            v-model="currentPage"
            :pages="pages"
          />
        </div>
      </va-card-content>
    </va-card>

    <va-modal v-model="showAddOrg" size="medium" :hideDefaultActions="true">
      <template #header>
        <p class="display-3 mb-5 mt-3 text--center">
          {{ $t("org_view.add_org") }}
        </p>
      </template>

      <slot>
        <form>
          <div class="row mb-3 ml-3 mr-3 formRow">
            <va-input
              class="flex xs12 md12"
              v-model="addForm.orgId"
              :label="$t('org_view.id')"
              :error="!!errors.addOrgErrors.orgIdErrors.length"
              :error-messages="errors.addOrgErrors.orgIdErrors"
            />
          </div>
        </form>
      </slot>

      <template #footer>
        <va-button color="danger" @click="cancelAdd" class="mr-3">
          {{ $t("common_forms.cancel") }}</va-button
        >
        <va-button @click="addOrg" icon="fa-icon fa fa-plus">{{
          $t("common_forms.create")
        }}</va-button>
      </template>
    </va-modal>

    <va-modal
      v-model="showConfirmDelete"
      size="medium"
      :hideDefaultActions="true"
      :message="
        $t('org_view.delete_org_confirm') +
        ' \'' +
        editedOrg.origOrgId +
        '\'? ' +
        $t('org_view.delete_org_confirm2') +
        ' ' +
        this.editedOrg.affectedUsers
      "
    >
      <template #header>
        <p class="display-3 mb-5 mt-3 text--center">
          {{ $t("org_view.delete_org") }}
        </p>
      </template>

      <template #footer>
        <va-button color="info" @click="cancelDelete" class="mr-3">
          {{ $t("common_forms.cancel") }}</va-button
        >
        <va-button color="danger" @click="confirmDelete" icon="fa fa-trash">{{
          $t("common_forms.delete")
        }}</va-button>
      </template>
    </va-modal>

    <va-modal v-model="showEditOrg" size="medium" :hideDefaultActions="true">
      <template #header>
        <p class="display-3 mb-5 mt-3 text--center">
          {{ $t("org_view.edit_org") }}
        </p>
      </template>

      <slot>
        <form>
          <div class="row mb-3 ml-3 mr-3 formRow">
            <va-input
              v-model="editedOrg.orgId"
              :label="$t('org_view.id')"
              :error="!!errors.editOrgErrors.orgIdErrors.length"
              :error-messages="errors.editOrgErrors.orgIdErrors"
            />
          </div>
        </form>
      </slot>

      <template #footer>
        <va-button color="danger" @click="cancelEdit" class="mr-3">
          {{ $t("common_forms.cancel") }}</va-button
        >
        <va-button @click="editOrgConfirm" icon="fa fa-pencil">{{
          $t("common_forms.edit")
        }}</va-button>
      </template>
    </va-modal>

    <va-modal
      v-model="showEditOrgConfirm"
      size="medium"
      :hideDefaultActions="true"
      :message="
        $t('org_view.edit_org_confirm') +
        ' \'' +
        editedOrg.origOrgId +
        '\' -> \'' +
        editedOrg.orgId +
        '\'? ' +
        $t('org_view.edit_org_confirm2') +
        ' ' +
        this.editedOrg.affectedUsers
      "
    >
      <template #header>
        <p class="display-3 mb-5 mt-3 text--center">
          {{ $t("org_view.edit_org") }}
        </p>
      </template>

      <template #footer>
        <va-button color="danger" @click="cancelEdit" class="mr-3">
          {{ $t("common_forms.cancel") }}</va-button
        >
        <va-button @click="editOrg" icon="fa fa-pencil">{{
          $t("common_forms.edit")
        }}</va-button>
      </template>
    </va-modal>
  </div>
</template>

<script>
import { debounce } from "lodash";

export default {
  data() {
    return {
      term: null,
      perPage: 10,
      perPageOptions: [10, 20, 50, 100],
      currentPage: 1,
      loading: true,
      tableData: [],
      users: [],
      showAddOrg: false,
      showConfirmDelete: false,
      showEditOrg: false,
      showEditOrgConfirm: false,
      addForm: {
        orgId: "",
      },
      editedOrg: {
        orgId: "",
        origOrgId: "",
        affectedUsers: [],
      },
      errors: {
        addOrgErrors: {
          orgIdErrors: [],
        },
        editOrgErrors: {
          orgIdErrors: [],
        },
      },
      searchBySelected: 0,
    };
  },

  created() {
    this.getOrganizations();
    this.getUsers();
    this.loading = false;
  },

  computed: {
    fields() {
      return [
        {
          key: "orgId",
          headerTitle: this.$t("org_view.id"),
          label: this.$t("org_view.id"),
          sortable: true,
        },
        {
          key: "orgType",
          headerTitle: this.$t("org_view.type"),
          label: this.$t("org_view.type"),
          sortable: true,
        },
        {
          key: "parentOrgId",
          headerTitle: this.$t("org_view.parentId"),
          label: this.$t("org_view.parentId"),
          sortable: true,
        },
        {
          key: "actions",
          headerTitle: "",
          label: "",
          align: "right",
        },
      ];
    },

    searchBy() {
      return [
        {
          id: 0,
          label: this.$t("org_view.id"),
          search: "id",
        },
        {
          id: 1,
          label: this.$t("org_view.parentId"),
          search: "parent",
        },
        {
          id: 2,
          label: this.$t("org_view.type"),
          search: "type",
        },
      ];
    },

    filteredData() {
      if (!this.term || this.term.length < 1) {
        return this.tableData;
      }

      if (this.searchBy[this.searchBySelected]) {
        switch (this.searchBy[this.searchBySelected].search) {
          case "id":
            return this.tableData.filter((item) => {
              return item.orgId
                .toLowerCase()
                .startsWith(this.term.toLowerCase());
            });
          case "parent":
            return this.tableData.filter((item) => {
              return item.parentOrgId
                .toLowerCase()
                .startsWith(this.term.toLowerCase());
            });
          case "type":
            return this.tableData.filter((item) => {
              return item.orgType
                .toLowerCase()
                .startsWith(this.term.toLowerCase());
            });
          default:
            return this.tableData;
        }
      } else {
        return this.tableData;
      }
    },

    pages() {
      const pages =
        this.perPage && this.perPage !== 0
          ? Math.ceil(this.filteredData.length / this.perPage)
          : this.filteredData.length;
      if (pages <= this.currentPage) {
        this.currentPage = pages === 0 ? 1 : pages;
      }
      return pages;
    },

    addFormReady() {
      return !this.errors.addOrgErrors.orgIdErrors.length;
    },
  },

  methods: {
    search: debounce(function (term) {
      this.term = term;
    }, 400),

    addOrg() {
      // console.log('Form submitted')
      // console.log(this.addForm)
      this.errors.addOrgErrors.orgIdErrors = this.addForm.orgId
        ? []
        : [this.$t("org_view.org_id_not_present")];
      if (!this.addFormReady) {
        // console.log('form not valid')
        return;
      }
      this.backend.addOrg(this.addForm).then((d2) => {
        if (d2 !== false) {
          this.addForm.orgId = "";
          this.getOrganizations();
          this.showAddOrg = false;
        } else {
          this.launchToast(this.$t("org_view.error_create"), "warning");
        }
      });

      // this.launchToast('TBD - not implemented yet!')
    },

    cancelAdd() {
      // console.log('Clearing data on cancel dialog')
      this.addForm.orgId = "";
      this.errors.addOrgErrors.orgIdErrors = [];
      this.showAddOrg = false;
    },

    getOrganizations() {
      this.backend.listOrganization().then((data) => {
        // console.log(data)
        if (data !== false) {
          this.tableData = data;
          for (let i = 0; i < this.tableData.length; i++) {
            this.tableData[i].actions = {
              id: i,
              type: this.tableData[i].orgType,
            };
          }
        } else {
          this.tableData = [];
          this.launchToast(this.$t("tables_common.error_data"), "danger");
        }
      });
    },

    getUsers() {
      this.backend.listUser().then((data) => {
        // console.log(data)
        if (data !== false) {
          this.users = data;
        } else {
          this.tableData = [];
          this.launchToast(this.$t("tables_common.error_data"), "danger");
        }
      });
    },

    removeOrg(orgId) {
      // console.log('Called remove')
      const org = this.tableData.find(({ actions }) => actions.id === orgId);
      this.editedOrg.origOrgId = org.orgId;
      const affected = this.users.filter((item) => {
        return item.organizationId === org.orgId;
      });
      this.editedOrg.affectedUsers = affected.map((a) => a.email);
      this.showConfirmDelete = true;
    },

    cancelDelete() {
      this.editedOrg.origOrgId = "";
      this.editedOrg.affectedUsers = [];
      this.showConfirmDelete = false;
    },

    confirmDelete() {
      this.backend.removeOrg(this.editedOrg.origOrgId).then((d2) => {
        if (d2 !== false) {
          this.editedOrg.origOrgId = "";
          this.editedOrg.affectedUsers = [];
          this.showConfirmDelete = false;
          this.getUsers();
          this.getOrganizations();
        } else {
          this.launchToast(this.$t("org_view.error_delete"), "danger");
        }
      });
    },

    editOrgModal(orgId) {
      // console.log('Called remove')
      const org = this.tableData.find(({ actions }) => actions.id === orgId);
      this.editedOrg.origOrgId = org.orgId;
      this.editedOrg.orgId = org.orgId;
      const affected = this.users.filter((item) => {
        return item.organizationId === org.orgId;
      });
      this.editedOrg.affectedUsers = affected.map((a) => a.email);
      this.showEditOrg = true;
    },

    cancelEdit() {
      this.editedOrg.origOrgId = "";
      this.editedOrg.orgId = "";
      this.editedOrg.affectedUsers = [];
      this.showEditOrg = false;
      this.showEditOrgConfirm = false;
    },

    editOrgConfirm() {
      this.errors.editOrgErrors.orgIdErrors = this.editedOrg.orgId
        ? []
        : [this.$t("org_view.org_id_not_present")];

      if (this.errors.editOrgErrors.orgIdErrors.length) {
        // console.log('form not valid')
        return;
      }
      this.showEditOrg = false;
      this.showEditOrgConfirm = true;
    },

    editOrg() {
      this.backend
        .editOrg(this.editedOrg.origOrgId, { orgId: this.editedOrg.orgId })
        .then((d2) => {
          if (d2 !== false) {
            this.editedOrg.origOrgId = "";
            this.editedOrg.orgId = "";
            this.editedOrg.affectedUsers = [];
            this.showEditOrgConfirm = false;
            this.getOrganizations();
            this.getUsers();
          } else {
            this.launchToast(this.$t("org_view.error_edit"), "warning");
          }
        });
    },

    launchToast(text, col) {
      this.$vaToast.init({
        message: text,
        position: "top-left",
        duration: 4000,
        fullWidth: false,
        color: col,
      });
    },
  },
};
</script>

<style lang="scss">
.formRow {
  min-width: 20vw;
}
</style>
